import React from 'react';
import { Box, Typography, Button, Grid, Card, CardMedia, Chip } from '@mui/material';
import './AIMIntroduction.css';

const AIMIntroduction = ({ image, description, appLink }) => {
  return (
    <>
      <Box className="aim-section-header">
        <Typography className="aim-section-title announcement">
          Artificial Intelligence Mentor
        </Typography>
        <Chip
          label="NEW"
          color="secondary"
          className="new-badge"
        />
      </Box>
      <Box className="aim-introduction-section">
        <Grid container spacing={3} alignItems="center">
          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <Card className="aim-image-card">
              <CardMedia component="img" image={image} alt="AIM App" className="aim-image" />
            </Card>
          </Grid>

          {/* Text Section */}
          <Grid item xs={12} md={6}>
            <Box className="aim-text-content">
              <Typography variant="h4" className="aim-title" gutterBottom>
                Discover AIM: Your AI Mentor
              </Typography>
              <Typography variant="body1" className="aim-description" paragraph>
                {description}
              </Typography>
              <Button
                variant="contained"
                className="aim-app-link-button"
                href={appLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Explore AIM App
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AIMIntroduction;
