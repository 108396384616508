const SchoolServicesList = [
    {
        title: "Smart Solutions: Revolutionizing School Applications",
        description: "We provide innovative applications and tailored solutions specifically designed for schools. Our goal is to enhance educational experiences and streamline administrative processes, ensuring that both educators and students can thrive in a tech-driven environment."
    },
    {
        title: "Teacher Training and Professional Development",
        description: "Workshops and Seminars: Provide ongoing professional development workshops focused on new teaching strategies, classroom management, and subject-specific knowledge. Certification Programs: Offer courses that help teachers earn certifications or advanced qualifications."
    },
    {
        title: "Curriculum Development and Implementation",
        description: "Customized Curriculum Design: Work with schools to develop and tailor curricula that meet their specific needs and align with educational standards. Curriculum Audits: Review existing curricula to identify gaps and areas for improvement."
    },
    {
        title: "Student Assessment and Evaluation Tools",
        description: "Assessment Frameworks: Provide tools and training for effective student assessment, including formative and summative assessments. Data Analysis Services: Help schools analyze student performance data to inform teaching strategies and interventions."
    },
    {
        title: "Technology Integration",
        description: "E-Learning Platforms: Assist schools in adopting and implementing e-learning platforms, including training for teachers and staff. Educational Software: Provide access to educational software that enhances learning and administrative efficiency."
    },
    {
        title: "Extracurricular and Enrichment Programs",
        description: "Clubs and Activities: Help schools develop and implement extracurricular programs that enhance students' skills and interests. Summer Programs: Design and manage summer camps or programs that focus on academic enrichment or personal development."
    },
    {
        title: "School Accreditation Support",
        description: "Accreditation Process Guidance: Assist schools in preparing for accreditation by guiding them through the requirements and processes. Documentation and Compliance: Help schools gather and prepare necessary documentation to meet accreditation standards."
    },
    {
        title: "Digital Apps",
        description: "Discover the Artificial Intelligence Mentor (AIM), a revolutionary platform designed to empower schools. AIM helps streamline student assessments with AI-powered evaluations, generate performance insights, and support teachers with advanced tools to foster student success. Enhance your institution’s academic capabilities with AIM. Visit AIM at ",
        appLink: "https://aim.edu-waves.com/"
    },
    {
        title: "Selling Stationary",
        description: ""
    },
    {
        title: "Preparing Classes and Labs",
        description: ""
    }
];

export default SchoolServicesList;
